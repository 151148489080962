import { ReactElement, useMemo } from 'react';
import * as S from './ScoreBar.styles';
import { BarDots } from 'components/atoms/BarDots/BarDots';

interface ScoreBarProps {
  score?: number;
  barDots?: number;
}

export const ScoreBar = ({ score = 1, barDots = 5 }: ScoreBarProps): ReactElement => {
  const roundedScore = useMemo(() => Math.round(score), [score]);

  const displayScore = useMemo(() => {
    const x = 1 + (score / 100) * 4;
    return x % 1 === 0 ? x : parseFloat(x.toFixed(1));
  }, [score]);

  const noScore = roundedScore === -1;

  return (
    <S.StyledBarContainer $grey={noScore}>
      <S.StyledBar>
        <S.StyledBarBackground>
          <S.StyledBarProgress style={{ transform: `scaleX(${roundedScore / 100})` }} />
        </S.StyledBarBackground>
        <BarDots dots={barDots} color="blueH" progress={roundedScore} />
      </S.StyledBar>
      <S.StyledBarTotal>{noScore ? 'N/A' : displayScore}</S.StyledBarTotal>
    </S.StyledBarContainer>
  );
};
