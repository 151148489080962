import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { theme } from 'styles/theme/default';
import { typeStyle } from 'styles/typeStyle';

export const OnboardingIntro = styled.section`
  position: relative;
  padding: 160px 0;
  color: ${theme.newColor.textDark};
  background-color: ${theme.newColor.surface100};

  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 40px 0;
  }
`;

export const Container = styled.div`
  display: flex;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    align-items: center;
    gap: 80px;
  }

  @media ${respondTo(MediaQuery.MAX_1023)} {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

export const Title = styled.h1`
  ${typeStyle.h1}
`;

export const Desc = styled.p`
  ${typeStyle.h4}

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-top: 80px;
  }

  @media ${respondTo(MediaQuery.MAX_1023)} {
    margin-top: 20px;
  }
`;

export const Content = styled.div`
  flex: 0 0 calc((100% - 80px) / 2);
`;

export const StyledImage = styled.img`
  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex: 0 0 calc((100% - 80px) / 2);
    width: 0;
  }

  @media ${respondTo(MediaQuery.MAX_1023)} {
    width: 100%;
  }
`;
