/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable babel/no-unused-expressions */
import { createContext, ReactElement, ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Modal } from '../Modal/Modal';
import * as S from './FormModal.styles';
import { useModal } from 'hooks/useModal';
import {
  RegisterFormItem,
  RegisterFormItemProps,
} from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { Input, InputProps } from 'components/atoms/Input/Input';
import { Select, SelectProps } from 'components/molecules/Select/Select';
import { TextArea, TextAreaProps } from 'components/atoms/TextArea/TextArea';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Form } from 'components/molecules/Form/Form';
import { RadioGroup, RadioGroupProps } from 'components/molecules/RadioGroup/RadioGroup';
import { ModalHeading } from 'components/molecules/ModalHeading/ModalHeading';
import { FormSticky } from 'components/molecules/FormSticky/FormSticky';
import { Checkbox, CheckboxProps } from 'components/atoms/Checkbox/Checkbox';

interface FormModalProps {
  children: ReactNode;
}

export type ModalRegisterFormItemProps = Omit<RegisterFormItemProps, 'children' | 'register'>;
export type ModalItemProps =
  | InputProps
  | SelectProps
  | TextAreaProps
  | RadioGroupProps
  | CheckboxProps;

export interface ModalData {
  title: string;
  items: Array<{
    type: 'input' | 'textarea' | 'select' | 'radio' | 'checkbox';
    registerFormItemProps: ModalRegisterFormItemProps;
    itemProps: ModalItemProps | any;
  }>;
  cancelCopy?: string;
  confirmCopy?: string;
}

interface FormModalContextProps {
  useFormModal: (data: ModalData) => Promise<unknown>;
}

export const FormModalContext = createContext({} as FormModalContextProps);

export const FormModal = ({ children }: FormModalProps): ReactElement => {
  const { data, setData, handleCancel, resolver, setModal } = useModal<ModalData>();

  const formItems = {
    input: Input,
    textarea: TextArea,
    select: Select,
    radio: RadioGroup,
    checkbox: Checkbox,
  };

  const handleFormSuccess = async (formData: any) => {
    resolver.current && resolver.current(formData);
    setData(false);
  };

  return (
    <>
      <AnimatePresence>
        {data && (
          <Modal $size="small" onClose={handleCancel}>
            <S.StyledFormModalInner>
              <ModalHeading title={data.title} />
              <Form onSuccess={handleFormSuccess}>
                <Flex
                  container
                  flexDirection="column"
                  gap="4rem"
                  style={{ paddingBottom: '14rem' }}
                >
                  {data.items.map((item) => {
                    const FormItem = formItems[item.type];

                    return (
                      <RegisterFormItem
                        register
                        registerErrors="required"
                        key={item.registerFormItemProps.label}
                        {...item.registerFormItemProps}
                      >
                        <FormItem {...(item.itemProps as any)} />
                      </RegisterFormItem>
                    );
                  })}
                </Flex>
                <FormSticky
                  handleCancel={handleCancel}
                  confirmCopy={data.confirmCopy}
                  cancelCopy={data.cancelCopy}
                  inModal
                />
              </Form>
            </S.StyledFormModalInner>
          </Modal>
        )}
      </AnimatePresence>
      <FormModalContext.Provider value={{ useFormModal: setModal }}>
        {children}
      </FormModalContext.Provider>
    </>
  );
};
