import { css } from 'styled-components';
import { HeadingType } from '../components/atoms/Heading/Heading.data';
import { fluidType } from './helpers/fluidType';
import { respondTo } from './helpers/respondTo';
import { MediaQuery } from './mediaQuery';

export const typeStyle = {
  [HeadingType.H1]: css`
    font-size: 4rem;
    line-height: 1.15;
    font-weight: 600;
    letter-spacing: -0.05em;

    @media ${respondTo(MediaQuery.MIN_768)} {
      font-size: 8rem;
    }
  `,
  [HeadingType.H2]: css`
    font-size: 4.5rem;
    letter-spacing: -0.03em;
    line-height: 1.1;
    font-weight: 600;

    @media ${respondTo(MediaQuery.MIN_768)} {
      font-size: 7.2rem;
      line-height: 1.075;
    }
  `,
  [HeadingType.H3]: css`
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 600;
    letter-spacing: -0.05em;

    @media ${respondTo(MediaQuery.MIN_768)} {
      font-size: 4rem;
    }
  `,
  [HeadingType.H4]: css`
    font-size: 2rem;
    line-height: 1.25;
    font-weight: 400;

    @media ${respondTo(MediaQuery.MIN_768)} {
      font-size: 2.4rem;
    }
  `,
  [HeadingType.H5]: css`
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.4375;

    @media ${respondTo(MediaQuery.MIN_768)} {
      font-size: 1.6rem;
      line-height: 1.4375;
    }
  `,
  [HeadingType.H6]: css`
    font-weight: 400;
    font-size: 3rem;
    line-height: 1.1;

    @media ${respondTo(MediaQuery.MIN_1024)} {
      line-height: 4.3rem;
      font-size: 4rem;
    }
  `,
  normal: css`
    ${fluidType(16, 18)}
  `,
  body: css`
    font-size: 1.6rem;
    line-height: 1.375;
  `,
  bodyRegular: css`
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: -0.05em;

    @media ${respondTo(MediaQuery.MIN_1024)} {
      font-size: 2rem;
    }
  `,
  label: css`
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: 500;
  `,
  labelB: css`
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 500;

    @media ${respondTo(MediaQuery.MIN_768)} {
      font-size: 1.2rem;
    }
  `,
  labelC: css`
    font-size: 1.4rem;
    line-height: 2.2rem;
  `,
  uppercase: css`
    font-size: 1.4rem;
    text-transform: uppercase;
  `,
  bold: css`
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.285;
  `,
  fileButton: css`
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #0066b3 !important;
    cursor: pointer;
  `,
} as const;

export type TypeStyleType = typeof typeStyle;
