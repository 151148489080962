/* eslint-disable max-lines */
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../../assets/img/logo/logo.svg';
import { ReactComponent as LogoFull } from '../../../assets/img/logo/logo-full.svg';
import { ReactComponent as THNK } from '../../../assets/img/logo/thnk.svg';
import { ReactComponent as Monochrome } from '../../../assets/img/logo/thnk-full-monochrome.svg';
import { theme } from 'styles/theme/default';

export const Feedback = styled.div`
  display: none;
  font-size: 14pt;
  line-height: 1.5;
  letter-spacing: -0.05em;

  @media print {
    display: block;
  }
`;

export const Highlight = styled.span`
  text-decoration: underline ${theme.newColor.primary100};
`;

export const FrontPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45pt;
  width: 100%;
  height: 100vh;
  page-break-after: always;
  background-color: ${theme.newColor.primary100};
  color: ${theme.newColor.textDark};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6pt;
  width: 100%;
  height: 100vh;
  padding: 40pt;
  page-break-after: auto;
  background-color: ${theme.newColor.primary100};
  color: ${theme.color.white};
`;

export const FrontPageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30pt;
  flex-grow: 1;
  margin: 0 40pt 40pt;
  padding: 30pt 18pt;
  background-color: ${theme.color.white};
`;

export const StyledLogo = styled(Logo)`
  width: 320pt;
  height: 320pt;
  opacity: 0.5;
  margin-left: auto;
`;

export const StyledLogoFull = styled(LogoFull)`
  width: 148pt;
  height: 80pt;
`;

export const StyledTHNK = styled(THNK)`
  width: 80pt;
  height: 16pt;
`;

export const StyledMonochrome = styled(Monochrome)`
  width: 148pt;
  height: 80pt;
`;

export const FrontPageTitle = styled.h1`
  font-size: 64pt;
  line-height: 1.15;
  font-weight: 600;

  span {
    color: ${theme.newColor.secondary};
  }
`;

export const FrontPageFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30pt;
  font-size: 20pt;
  line-height: 1.5;
  font-weight: 500;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  page-break-after: always;
`;

export const SeationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20pt;
  padding: 24pt 40pt;
  background-color: ${theme.newColor.surface100};
`;

export const SeationBanner = styled.div`
  padding: 24pt 40pt;
  background-color: ${theme.newColor.primary100};
  color: ${theme.color.white};
`;

export const SectionTitle = styled.h2`
  font-size: 32pt;
  line-height: 1.25;
  font-weight: 600;
`;

export const SectionSubTitle = styled.h2`
  font-size: 20pt;
  line-height: 1.25;
  font-weight: 600;
`;

export const SeationContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24pt 40pt 40pt;
`;

export const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18pt;
  flex: 0 0 calc((100% - 30pt) / 2);
`;

export const QuestionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30pt;

  &:not(:first-of-type) {
    padding-top: 12pt;
    margin-top: 18pt;
    border-top: 0.75pt solid ${theme.newColor.border300};
  }
`;

export const AnswerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6pt;
  font-weight: 600;
`;

export const Score = styled.div`
  padding: 1.5pt 3pt;
  font-weight: 500;
  background-color: ${theme.newColor.surface100};
`;

export const SectionChunk = styled.div`
  page-break-after: always;

  &:not(:first-of-type) {
    margin-top: 30pt;
  }
`;

export const ChunkTitle = styled.h3`
  display: inline-block;
  font-size: 24pt;
  line-height: 1.25;
  font-weight: 600;
  padding-bottom: 6pt;
  margin-bottom: 18pt;
  border-bottom: 2.25pt solid ${theme.newColor.secondary};
`;

export const AnswerWrapper = styled.div`
  padding-top: 6pt;
  page-break-inside: avoid;
`;

export const OpenAnswer = styled.div`
  padding: 3pt 6pt;
  font-weight: 500;
  background-color: ${theme.newColor.surface100};
`;

export const Quote = styled.blockquote`
  position: relative;
  font-size: 64pt;
  line-height: 1.15;
  font-weight: 600;
  margin: auto 0 0;

  &::before {
    content: '“';
    position: absolute;
    bottom: calc(100% + 6pt);
    left: 0;
    font-size: 80pt;
    line-height: 0;
    opacity: 0.5;
    font-family: 'auto';
  }

  ${Highlight} {
    text-decoration-color: ${theme.newColor.secondary};
  }
`;

export const Author = styled.div`
  font-size: 24pt;
`;

export const GridContainer = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12pt;
  margin-top: 8pt;
  page-break-inside: avoid;
`;

export const GridContainerAlt = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12pt;
  margin-top: 8pt;
  page-break-inside: avoid;
`;

export const Note = styled.div`
  border: 1px solid ${theme.newColor.border300};
  background: linear-gradient(to left, ${theme.newColor.secondary} 1px, transparent 1px),
    linear-gradient(to top, ${theme.newColor.primary100} 1px, white 1px);
  background-size: 20pt 100%, 100% 9.2%;
  background-repeat: no-repeat, repeat;

  span {
    display: block;
    font-size: 14pt;
    font-weight: 500;
    line-height: 1.5;
    width: 20pt;
    height: 9.2%;
    text-align: center;
  }
`;

export const TargetNote = styled.div`
  grid-column: span 4;
  display: flex;
  align-items: center;
  gap: 15pt;
  padding: 0 12pt;
  background-color: ${theme.newColor.surface100};

  svg {
    flex-shrink: 0;
  }

  div {
    flex-grow: 1;
    background: linear-gradient(to bottom, ${theme.newColor.border300} 1px, transparent 1px);
    background-size: 100% 47%;
    height: 40pt;
  }
`;

export const Conclusion = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  background-color: ${theme.newColor.primary90};
  color: ${theme.newColor.primary100};
  padding: 6pt;
  font-weight: 600;

  ${Highlight} {
    text-decoration-color: ${theme.newColor.textDark};
  }
`;

export const Dots = styled.div`
  flex-grow: 1;
  margin: 30pt 4pt 10pt;
  background: radial-gradient(circle at 1px 1px, ${theme.newColor.primary100} 2px, transparent 2px);
  background-size: 4.1% 13%;
`;

export const Skills = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6pt;
  font-weight: 600;
  color: ${theme.newColor.primary100};

  ${Highlight} {
    text-decoration-color: ${theme.newColor.secondary};
  }
`;

export const SkillsNote = styled.div`
  flex-grow: 1;
  background-color: ${theme.newColor.surface100};
  padding: 24pt 12pt;

  div {
    height: 100%;
    background: linear-gradient(to bottom, ${theme.newColor.border300} 1px, transparent 1px);
    background-size: 100% 19.5%;
  }
`;

export const SectionQuote = styled.q`
  position: relative;
  font-weight: 600;

  &::before {
    content: '“';
    position: absolute;
    bottom: 100%;
    left: 0;
    font-size: 30pt;
    line-height: 0;
    font-family: 'auto';
    color: ${theme.newColor.secondary};
  }

  ${Highlight} {
    text-decoration-color: ${theme.newColor.secondary};
  }
`;

export const SectionQuoteContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5pt;
  margin-top: 20pt;
  font-size: 18pt;
  color: ${theme.newColor.primary100};
  letter-spacing: -0.05em;
`;

export const SectionQuoteAuthor = styled.div`
  opacity: 0.5;
  font-weight: 500;
`;

export const ConclusionAlt = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  grid-row: span 3;
  padding: 12pt;
  background-color: ${theme.newColor.primary90};
  color: ${theme.newColor.primary100};
  font-weight: 600;
`;

export const ConclusionAltNote = styled.div`
  flex-grow: 1;
  background: linear-gradient(to bottom, ${theme.newColor.primary100} 1px, transparent 1px);
  background-size: 100% 9.5%;
  margin: 20pt 0 10pt;
`;

export const Objectives = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25pt;
  grid-column: span 2;
  grid-row: span 3;
`;

export const ObjectivesHeader = styled.div`
  padding: 12pt;
  background-color: ${theme.newColor.primary100};
  color: ${theme.color.white};
  font-weight: 600;

  ${Highlight} {
    text-decoration-color: ${theme.newColor.secondary};
  }
`;

export const ObjectivesDots = styled.div`
  flex-grow: 1;
  background: radial-gradient(circle at 1px 1px, ${theme.newColor.primary100} 2px, transparent 2px);
  background-size: 6.2% 6%;
`;
