/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { size } from 'styles/helpers/size';
import { ColorKey } from 'styles/theme/default';

interface BarDotsStyleProps {
  color?: ColorKey;
  padding?: string;
}

export const StyledBarDots = styled.div<{
  $selected?: boolean;
  $clickable: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.5;
  ${({ $selected, theme, $clickable }) =>
    !$clickable
      ? $selected
        ? css`
            background-color: ${theme.color.white};
          `
        : css`
            background-color: ${theme.newColor.primary100};
          `
      : $selected
      ? css`
          color: ${theme.color.white};
          background: ${theme.newColor.primary100};
        `
      : css`
          color: ${theme.newColor.primary100};
          background: ${theme.newColor.primary90};
        `};
  ${({ $clickable }) =>
    $clickable
      ? `${size('3.2rem')}; cursor: pointer;`
      : `${size('1.2rem')}; font-size: 0; line-height: 0;`}
  border-radius: 50%;
  z-index: 3;
`;

export const StyledBarDotsWrapper = styled.div<BarDotsStyleProps>`
  ${size('100%')}
  ${({ padding }) => `padding: 0 ${padding}`};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
