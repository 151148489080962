/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
import styled, { css } from 'styled-components';
import { ColorKey } from '../../../styles/theme/default';
import { AbstractButton } from '../AbstractButton/AbstractButton';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

type ButtonSizes = 'regular' | 'large';

interface PrimaryButtonProps {
  $color?: ColorKey;
  $backgroundColor?: ColorKey;
  $disabledColor?: ColorKey;
  $disabledBackgroundColor?: ColorKey;
  $size?: ButtonSizes;
  $mobileSize?: ButtonSizes;
  $outline?: boolean;
  $marginBlock?: boolean;
  disabled?: boolean;
}

export const PrimaryButton = styled(AbstractButton)<PrimaryButtonProps>`
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 600;
  padding: 1.3rem 2.4rem;
  text-transform: uppercase;
  border-radius: 2px;
  transition: background-color 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-block: ${({ $marginBlock }) => $marginBlock && '0.5rem'};
  color: ${({ theme, $color }) => theme.color[$color || 'white']};
  opacity: ${({ disabled }) => disabled && '0.77'};

  ${({ $size, $mobileSize }) =>
    $size !== 'regular' &&
    `
  ${$mobileSize === 'regular' ? `@media ${respondTo(MediaQuery.MIN_768)} {` : ''}
    padding: 2rem 4rem;
    min-width: 25.5rem;
    ${$mobileSize === 'regular' ? '};' : ''}
  `}

  ${({ theme, $backgroundColor, $outline }) =>
    ($backgroundColor || !$outline) &&
    `background-color: ${theme.color[$backgroundColor || 'blueA']};`}

  ${({ $outline, theme, $color }) =>
    $outline &&
    css`
      background-color: transparent;
      border: solid 1px ${theme.color[$color || 'blueA']};
      color: ${theme.color[$color || 'blueA']};
    `}


  ${({ disabled, $outline, theme, $disabledColor, $disabledBackgroundColor }) =>
    disabled &&
    css`
      filter: grayscale(1);
      // todo: in some cases the following styles are not being applied correctly, probably needs refactor and simplification.
      ${!$outline && `background-color: ${theme.color[$disabledBackgroundColor || 'greyA']};`}
      ${$outline && $disabledColor && `border-color: ${theme.color[$disabledColor || 'greyA']};`}
      ${$disabledColor && `color: ${theme.color[$disabledColor]};`}
      cursor: not-allowed;
    `}

  @media (hover: hover) {
    &:not([disabled]):hover {
      ${({ $outline, $color, $backgroundColor }) =>
        $outline
          ? css`
              border-color: ${({ theme }) =>
                theme.utils.hexToRgba(theme.color[$color || 'blueA'], 0.5)};
            `
          : css`
              background-color: ${({ theme }) =>
                theme.utils.hexToRgba(theme.color[$backgroundColor || 'blueA'], 0.77)};
            `}
    }
  }

  svg {
    margin-left: 1rem;
    width: 18px;
    height: 18px;
  }
`;
