import styled, { css } from 'styled-components';
import { Ease } from 'styles/easing';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { TransitionDuration } from 'styles/variables';

export const StyledGraphic = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20rem;
  height: 20rem;

  @media ${respondTo(MediaQuery.MIN_480)} {
    width: 30rem;
    height: 30rem;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: 36.2rem;
    height: 36.2rem;
  }

  @media ${respondTo(MediaQuery.MIN_1280)} {
    width: 46.2rem;
    height: 46.2rem;
  }

  & > * {
    position: absolute;
  }
`;

export enum CircleVariants {
  Active = 'Active',
  Normal = 'Normal',
}

interface CircleStyleProps {
  variant?: CircleVariants | boolean;
}

const StyledCircleActive = css`
  color: ${({ theme }) => theme.newColor.primary100};
  background: ${({ theme }) => theme.color.white};
`;

export const StyledCircle = styled.div<CircleStyleProps>`
  width: 3.8rem;
  height: 3.8rem;
  line-height: 3.8rem;
  font-size: 1.5rem;
  border-radius: 50%;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.newColor.primary100};
  border: 1px solid ${({ theme }) => theme.color.white};
  z-index: 1;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: opacity ${TransitionDuration.Normal} ${Ease.EaseInSine},
    background ${TransitionDuration.Normal} ${Ease.EaseInSine},
    color ${TransitionDuration.Normal} ${Ease.EaseInSine};

  &:hover {
    ${StyledCircleActive};
  }

  ${({ variant }) => variant === CircleVariants.Active && StyledCircleActive}

  &:nth-child(1) {
    left: 50%;
    top: 0;
  }

  &:nth-child(2) {
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
  }

  &:nth-child(3) {
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
  }

  &:nth-child(4) {
    left: 0;
    top: 50%;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: 6.3rem;
    height: 6.3rem;
    line-height: 6.3rem;
    font-size: 2.4rem;
  }
`;

export const StyledCirclePositon = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    background: ${({ theme }) => theme.color.white};
    border-radius: 50%;
    left: 50%;
    top: 0;
    transform: translate(-50%, -40%);
  }
`;

export const StyledCirclePositonContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledIconContainer = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${respondTo(MediaQuery.MAX_1023)} {
    svg {
      width: 4rem;
      height: 5.4rem;
    }
  }
`;
