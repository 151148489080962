import styled from 'styled-components';
import { Ease } from 'styles/easing';

const animationTransition = `${Ease.EaseOutCubic} 500ms`;

export const StyledScoreBar = styled.div``;

export const StyledBarContainer = styled.div<{ $grey?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  margin: 3rem 0;
  justify-content: space-between;
  gap: 2rem;
  filter: ${({ $grey }) => ($grey ? 'grayscale(1)' : 'none')};
`;

export const StyledBar = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledBarBackground = styled.div`
  height: 0.4rem;
  border-radius: 1rem;
  width: 100%;
  background: ${({ theme }) => theme.newColor.primary100};
  overflow: hidden;
`;

export const StyledBarProgress = styled.div`
  position: relative;
  z-index: 4;
  background: currentColor;
  height: 100%;
  width: 100%;
  transition: transform ${animationTransition};
  transform-origin: left;
`;

export const StyledBarTotal = styled.div`
  position: absolute;
  right: 0;
  bottom: calc(100% + 30px);
  color: ${({ theme }) => theme.color.white};
  font-size: 1.4rem;
  min-width: 1rem;
  text-align: right;
`;
