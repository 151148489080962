import styled from 'styled-components';
import { motion } from 'framer-motion';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { typeStyle } from 'styles/typeStyle';
import { theme } from 'styles/theme/default';

export const OnboardingHero = styled(motion.section)`
  padding-top: 114px;
  color: ${theme.newColor.textDark};

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-bottom: 160px;
  }

  @media ${respondTo(MediaQuery.MAX_1023)} {
    margin-bottom: 80px;
  }
`;

export const Container = styled.div`
  display: flex;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    align-items: center;
    gap: 80px;
  }

  @media ${respondTo(MediaQuery.MAX_1023)} {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Title = styled.h1`
  ${typeStyle.h1}
`;

export const Desc = styled.p`
  ${typeStyle.h3}

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-top: 40px;
  }

  @media ${respondTo(MediaQuery.MAX_1023)} {
    margin-top: 20px;
  }
`;

export const Content = styled.div`
  flex: 0 0 calc((100% - 80px) / 2);
`;

export const StyledImage = styled.img`
  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex: 0 0 calc((100% - 80px) / 2);
    width: 0;
  }

  @media ${respondTo(MediaQuery.MAX_1023)} {
    width: 100%;
  }
`;
