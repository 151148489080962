import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import * as S from './Intro.styles';
import introImage from 'assets/img/onboarding/onboarding-intro.png';

export const Intro = (): ReactElement => {
  return (
    <S.OnboardingIntro>
      <div className="wrap">
        <S.Container>
          <S.StyledImage src={introImage} alt="Hero image" />
          <S.Content>
            <S.Title>
              <FormattedMessage
                description="Onboarding - Intro - Title"
                defaultMessage="What is the 360º Mirror?"
              />
            </S.Title>
            <S.Desc>
              <FormattedMessage
                description="Onboarding - Intro - Paragraph"
                defaultMessage="The 360º Mirror is a comprehensive leadership development tool. It has been developed by THNK to support leaders to reflect on their leadership and embark on a path of growth.
                "
              />
              <br />
              <br />
              <FormattedMessage
                description="Onboarding - Intro - Paragraph"
                defaultMessage="
                By using this tool you'll learn about your strengths and growth areas, providing you with actionable insights. You will be challenged to take action on those insights to enhance your unique set of gifts."
              />
            </S.Desc>
          </S.Content>
        </S.Container>
      </div>
    </S.OnboardingIntro>
  );
};
