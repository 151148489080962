import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { FontWeight } from 'styles/variables';

export const StyledObjective = styled.div`
  flex-basis: 100%;
  min-width: 100%;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 0 ${({ theme }) => theme.sitePaddings.mobile};
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    max-width: 28rem;
  }
`;

export const StyledObjectiveTitle = styled.div`
  font-size: 2rem;
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 1.9rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    font-size: 2.4rem;
    font-weight: ${FontWeight.Medium};
  }
`;

export const StyledObjectiveContent = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.redA};
  padding-left: 2.4rem;
  font-size: 1.6rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    max-width: 66%;
  }

  p {
    margin-bottom: 2.4rem;
    line-height: 2.2rem;
  }

  h6 {
    font-weight: ${FontWeight.SemiBold};
  }

  h6 + ul {
    margin-top: 1rem;

    @media ${respondTo(MediaQuery.MIN_1024)} {
      margin-top: 1.6rem;
    }
  }

  li {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    @media ${respondTo(MediaQuery.MIN_1024)} {
      font-weight: ${FontWeight.Medium};
      font-size: 1.2rem;
    }

    &::before {
      content: '';
      vertical-align: center;
      display: inline-block;
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.redB};
      margin-right: 0.8rem;
    }
  }
`;
