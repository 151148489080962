import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const Message = styled.p`
  margin-bottom: 2.5rem;
`;
