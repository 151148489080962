import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { typeStyle } from 'styles/typeStyle';
import { theme } from 'styles/theme/default';

export const OnboardingOutro = styled.section`
  position: relative;
  padding: 160px 0;
  text-align: center;
  background-color: ${theme.newColor.secondary};

  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 120px 0;
  }
`;

export const Headline = styled.h2`
  ${typeStyle.h1};
  color: ${({ theme }) => theme.color.white};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-top: 80px;
  }

  @media ${respondTo(MediaQuery.MAX_1023)} {
    margin-top: 40px;
  }
`;
