import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import * as S from './Hero.styles';
import heroImage from 'assets/img/onboarding/onboarding-hero.png';

export const Hero = (): ReactElement => {
  return (
    <S.OnboardingHero>
      <div className="wrap">
        <S.Container>
          <S.Content>
            <S.Title>
              <FormattedMessage
                description="Onboarding - Hero - Headline"
                defaultMessage="Develop your leadership"
              />
            </S.Title>
            <S.Desc>
              <FormattedMessage
                description="Onboarding - Hero - Description"
                defaultMessage="Level up your leadership by reflecting, soliciting feedback, and taking action."
              />
            </S.Desc>
          </S.Content>
          <S.StyledImage src={heroImage} alt="Hero image" />
        </S.Container>
      </div>
    </S.OnboardingHero>
  );
};
