import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import * as S from './StartAssessment.styles';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Path } from 'routes/Path';

export const StartAssessment = (): ReactElement => {
  const { push } = useHistory();

  const handleStartAssessment = () => push(Path.Dashboard);

  return (
    <S.OnboardingOutro>
      <div className="wrap">
        <S.Headline>
          <FormattedMessage
            description="Onboarding - Outro - Heading"
            defaultMessage="Let’s start the assessment"
          />
        </S.Headline>
        <S.ButtonContainer>
          <PrimaryButton onClick={handleStartAssessment}>
            <FormattedMessage
              description="Onboarding - Outro - Cta"
              defaultMessage="Start assessment"
            />
          </PrimaryButton>
        </S.ButtonContainer>
      </div>
    </S.OnboardingOutro>
  );
};
