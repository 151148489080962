import { ReactElement, useRef } from 'react';
import { useIntl } from 'react-intl';
import { carouselItems } from './Objectives.data';
import { Graphic } from './Graphic/Graphic';
import { Objective } from './Objective/Objective';
import * as S from './Objectives.styles';
import { MediaQuery } from 'styles/mediaQuery';
import { useSectionScroll } from 'hooks/useSectionScroll';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Hide } from 'components/atoms/Hide/Hide';
import { useDeviceState } from 'hooks/useDeviceState';

export const Objectives = (): ReactElement => {
  const { sectionRef, percentage, setPercentage, getItemScrollPosition } = useSectionScroll({
    disableMobile: true,
  });
  const objectivesLeftRef = useRef(null);
  const { isMobile } = useDeviceState();
  const intl = useIntl();
  const sectionTitle = intl.formatMessage({
    description: 'Onboarding Objectives Title',
    defaultMessage: 'How does it work?',
  });
  const carouselData = Object.values(carouselItems);

  const graphicCircleClick = (index: number) => {
    if (isMobile) {
      setPercentage(index * 25);
    } else {
      window.scrollTo({ top: getItemScrollPosition(index) });
    }
  };

  return (
    <S.StyledObjectives ref={sectionRef}>
      <div className="wrap">
        <S.StyledObjectivesSticky>
          <S.StyledHide screen={MediaQuery.MIN_1024}>
            <S.StyledHeading
              type={HeadingType.H2}
              dangerouslySetInnerHTML={{ __html: sectionTitle }}
            />
          </S.StyledHide>
          <S.StyledObjectivesInner>
            <S.StyledObjectivesLeft ref={objectivesLeftRef}>
              <Hide screen={MediaQuery.MAX_1023}>
                <S.StyledHeading
                  type={HeadingType.H2}
                  dangerouslySetInnerHTML={{ __html: sectionTitle }}
                />
              </Hide>
              <S.StyledObjectivesContainer>
                <S.StyledObjectivesCarousel
                  style={{
                    transform: `translate3d(calc(-${
                      Math.floor(percentage / 25) * 100
                    }% + 1px),0,0)`,
                  }}
                >
                  {[...new Array(4).keys()].map((element) => (
                    <Objective
                      key={`objective-${element}`}
                      title={carouselData[element * 2]}
                      content={carouselData[element * 2 + 1]}
                      index={element + 1}
                    />
                  ))}
                </S.StyledObjectivesCarousel>
              </S.StyledObjectivesContainer>
            </S.StyledObjectivesLeft>
            <S.StyledObjectivesRight>
              <S.StyledObjectivesRightInner>
                <Graphic onCircleClick={graphicCircleClick} percentage={percentage} />
              </S.StyledObjectivesRightInner>
            </S.StyledObjectivesRight>
          </S.StyledObjectivesInner>
        </S.StyledObjectivesSticky>
        <S.StyledObjectivesGap />
      </div>
    </S.StyledObjectives>
  );
};
